.footera {
	color: black;
	text-decoration: none;
}

.footer {
	padding-left: 3em;
	margin-left: auto;
	margin-right: 10px;
	margin-top: 1em;
}

.pie {
	padding-right: 4px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
}

.final {
	width: 97%;
}

.final h2 {
	font-size: 4vw;
	color: #27A99F;
}

.final h1 {
	color: black;
	font-size: 16vw;
	line-height: 58px;
	margin-top: 20px;
}

.imagen{
	width: 97%;
	margin-top: -50px;
}

.agente {
	width: 50%;
	border: 7px solid #A28100;
	border-radius: 1000px;	
}

.info {
	text-align: center;
}

#zocalo {
	width: 100%;
	height: 50px;
	background: #00A99F;
}



@media (min-width: 488px){
	.final h1 {
		margin-top: 0.5em;
	}
}


@media (min-width: 1028px){
	
	.pie {
		text-align:inherit;
		width: 100%;
	}
	
	.final{
		width: 50%;
		margin-left: 1em;
	}
	
	.final h1 {
		font-size: 8vw;
		line-height: 10vw;
	}
	
	.final h2 {
		font-size: 2vw;
	}
	
	.imagen {
	width: 40%;
	margin-top: 100px;
	text-align: center;
	}
		
	.info {
		font-size: 2vw;
		text-align: left;
	}
	
	#zocalo {
	height: 84px;
}

}

@media (min-width: 1200px){
	.final{
		margin-left: 2em;
	}
}