.actions-right [type='checkbox'] {
    width: 25px;
    height: 25px;
}

.actions-right button {
    margin-top: -14px !important;
    padding: 0px!important;
    width: 20px;
    height: 20px;
}

a.Sidebar-logoNormal-101 {
    text-transform: lowercase!important;
}
.Sidebar-img-105 {
    width: 50px!important;
}

.Sidebar-logoMini-99 {
    width: 50px!important;
    margin-top:-4px!important;
}

.rt-td [type='checkbox'] {
    width: 25px!important;
    height: 25px!important;
}