@charset "UTF-8";
@import url("presentacion.css");
@import url("disney.css");
@import url("universal.css");
@import url("miami.css");
@import url("crucero.css");
@import url("pie.css");

@font-face {
	font-family: "Garden Grown US B";
	src:url("../font/GardenGrownUS-B.ttf");
}

@font-face {
    font-family: "DejaVu Sans";
    src: url("../font/DejaVuSans.ttf") format("truetype");
  }

  @font-face {
    font-family: "Helvetica Neue";
    src: url("../font/HelveticaNeue.ttf") format("truetype");
  }
  @font-face {
    font-family: "Helvetica Neue B";
    src: url("../font/HelveticaNeueBd.ttf") format("truetype");
  }

  @font-face {
    font-family: "Helvetica";
    src: url("../font/Helvetica.ttf") format("truetype");
  }
  @font-face {
    font-family: "Helvetica B";
    src: url("../font/Helvetica-Bold.ttf") format("truetype");
  }
  @font-face {
    font-family: "sans-serif";
    src: url("../font/comic.ttf") format("truetype");
  }
  @font-face {
    font-family: "sans-serif B";
    src: url("../font/ComicSansMSBold.ttf") format("truetype");
  }
  @font-face {
    font-family: "Comic Sans MS";
    src: url("../font/comic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Comic Sans MS B";
    src: url("../font/ComicSansMSBold.ttf") format("truetype");
  }

  @font-face {
    font-family: "DejaVu Sans";
    font-style: italic;
    src: url("../font/DejaVuSans-Oblique.ttf") format("truetype");
  }

  @font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    font-style: italic;
    src: url("../font/DejaVuSans-Oblique.ttf") format("truetype");
  }


.body {
	margin: 0px;
	padding: 0px;
	font-family: "Helvetica Neue", "Helvetica", "sans-serif", "Comic Sans MS";
	background-image: url(../img/fondo.jpg);
	background-repeat: repeat-y;
	background-size: 50px auto;
	background-position: left;
	background-color: white;
	color: black;
}

.h1 {
	font-family: "Garden Grown US B";
	font-size: 12vw;
	color: #27A99F;
}
.h3 {
	font-weight: 400;
	line-height: 16pt;
	font-size: 12pt;
}

#contenedor {
	padding-top: 2em;
	margin-left: 25px;
}

.titular {
	font-size: 10vw;
	margin-bottom: 10px;
	margin-top: 6px;
	padding-left: 10px;
}

.foto {
	width: 70%;
	height: 100%;
	padding-right: 10px;
}

.span{
	color:black;
	font-family: "Comic Sans MS";
}

@media (min-width:768px) {
	
.body {
	background-size: 169px auto;
}

.h1 {
	font-size: 10vw;
}

.h3 {
	font-size: 14pt;
}

.titular {
	font-size: 5vw;
	margin-bottom: 10px;
	margin-top: 0px;
}

#contenedor {
	margin-left: 156px;
}

.foto{
	width: 400px;
	padding-right: 30px;
	}	
	
}

@media (min-width:1200px) {

.foto {
	width: 50%;
}
}
