/* YearSelection.css */
.year-selection {
    width: 200px;
    
}

.year-select {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 5px;
    font-size: 14px;
}

.year-select__control {
    border: none;
}

.year-select__input {
    color: #333;
}

.year-select__menu {
    border: 1px solid #ccc;
    border-top: none;
    max-height: 150px;
    /* Ajusta la altura máxima del menú según tus necesidades */
}

.year-select__option {
    padding: 8px 16px;
}

.year-select__option--is-focused {
    background-color: #f0f0f0;
}

.year-select__option--is-selected {
    background-color: #007bff;
    color: white;
}

.year-select__placeholder {
    color: #999;
}

.year-select__single-value {
    color: #333;
}